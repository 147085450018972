import { EventEmitter, Injectable, Output } from "@angular/core";
import { Values } from "../../../values/values";

// Serivce
import { RoutingService } from "../../services/routing/routing.service";
import { EndpointClientService } from "../endpoint-client.service";
import { EndpointService } from "../../services/generic/endpoint.service";
import { TemplateService } from "app/services/generic/custom/template-element";
import { JourneyService } from 'app/services/journey/journey.service';
import { RedsysService } from 'app/services/redsys.service';
import { PaycometService } from 'app/services/paycomet.service';
import { PrintTicketService } from 'app/services/generic/custom/print-ticket.service';

// Material
import { MatDialog } from "@angular/material/dialog";

// Component
import { GenericService } from "../../services/generic/generic.service";
import { AuthService } from "../../services/auth/auth.service";
import { SideNavService } from "app/utils/SideNavService";

import { UserFunctionsService } from "../../services/functions/user-functions.service";
import { DemoDialogComponent } from "app/demo-dialog/demo-dialog.component";
import { FunctionsService } from "app/services/generic/functions.service";
import { ColorPickerDialog } from "app/activities/subcomponents/color-picker-dialog/color-picker-dialog";
import { setCategories } from "app/activities/objects/category";

import { EMPTY } from "rxjs";
import { M, S } from "@angular/cdk/keycodes";
import { log } from "console";
import { es } from "date-fns/locale";

@Injectable({
  providedIn: "root",
})
export class GenericClientService {
  
  @Output() update_category: EventEmitter<boolean> =
  new EventEmitter<boolean>();

  constructor(
    private routingService: RoutingService,
    private endpointClientService: EndpointClientService,
    private endpointService: EndpointService,
    private genericService: GenericService,
    private authService: AuthService,
    private sidenavService: SideNavService,
    private UserFunctionsService: UserFunctionsService,
    private functionsService: FunctionsService,
    public templateService: TemplateService,
    public journeyService: JourneyService,
    public redsysService: RedsysService,
    public paycometService: PaycometService,
    public printTicketService: PrintTicketService,
  ) { }

  buildURLFichaCotizacion(posInm) {
    let idLanguage = null;
    let nombrePlantilla = null;
    let nombreEmpresa = null;
    let idEmpresa = 0;
    let idCotizacion = 0;
    let values = JSON.parse(JSON.stringify(this.functionsService.tmpArray));
    for (let i in values) {
      if (
        (values[i]["database"] == 1 &&
          values[i]["table"] == "gest_log_cotizaciones_comodin" &&
          values[i]["field"] == "idioma")
        ||
        (values[i]["id_db"] == 1 &&
          values[i]["bd_table"] == "gest_log_cotizaciones_comodin" &&
          values[i]["bd_field"] == "idioma")
      ) {
        idLanguage = values[i]["value"];
      }
      if (
        (values[i]["database"] == 1 &&
          values[i]["table"] == "gest_log_cotizaciones_comodin" &&
          values[i]["field"] == "plantilla")
        ||
        (values[i]["id_db"] == 1 &&
          values[i]["bd_table"] == "gest_log_cotizaciones_comodin" &&
          values[i]["bd_field"] == "plantilla")
      ) {
        nombrePlantilla = values[i]["value"];
      }
      if (
        (values[i]["database"] == 1 &&
          values[i]["table"] == "gest_log_cotizaciones" &&
          values[i]["field"] == "id_cotizacion")
        ||
        (values[i]["id_db"] == 1 &&
          values[i]["bd_table"] == "gest_log_cotizaciones" &&
          values[i]["bd_field"] == "id_cotizacion")
      ) {
        if (posInm == -1) {
          idCotizacion = values[i]["value"];
        } else {
          idCotizacion = values[i]["value"][posInm];
        }
      }
      if (
        (values[i]["database"] == 1 &&
          values[i]["table"] == "gest_log_cotizaciones_comodin" &&
          values[i]["field"] == "id_empresa")
        ||
        (values[i]["id_db"] == 1 &&
          values[i]["bd_table"] == "gest_log_cotizaciones_comodin" &&
          values[i]["bd_field"] == "id_empresa")
      ) {
        idEmpresa = values[i]["value"];
      }
      if (
        (values[i]["database"] == 1 &&
          values[i]["table"] == "gest_log_cotizaciones_comodin" &&
          values[i]["field"] == "url_empresa")
        ||
        (values[i]["id_db"] == 1 &&
          values[i]["bd_table"] == "gest_log_cotizaciones_comodin" &&
          values[i]["bd_field"] == "url_empresa")
      ) {
        nombreEmpresa = values[i]["value"];
      }
    }

    if (nombreEmpresa == null && this.authService.companyGenericName) {
      nombreEmpresa = this.authService.companyGenericName;
    }
    if (idEmpresa == null && this.authService.empresaId) {
      idEmpresa = this.authService.empresaId;
    }

    return (
      Values.ROOT_URL +
      "/" +
      idLanguage +
      "/" +
      nombreEmpresa +
      "/" +
      nombrePlantilla +
      "/sth/" +
      idEmpresa +
      "&&" +
      idCotizacion
    );
  }

  buildURLFichaInmueble(structure, posInm, esPresupuesto = 0) {
    let idVivienda = null;
    let idPresupuesto = null;
    let refInmo = null;
    let nameInmo = null;
    let nombreEmpresa = null;
    let idEmpresa = 0;
    
    let idFALanguage = this.genericService.findFAWithTableField(1, "vivienda_comodin", "idioma", structure["id_functional_parent_initial_dsb"]);
    let idLanguage = 'es';
    if(idFALanguage && idFALanguage['tmp_value']) this.genericService.getValuesQuerySelection(idFALanguage)[0].label;

    let nombrePlantilla = this.genericService.findFAWithTableField(1, "vivienda_comodin", "plantilla", structure["id_functional_parent_initial_dsb"]);
    if(nombrePlantilla && nombrePlantilla['tmp_value']) nombrePlantilla = nombrePlantilla['tmp_value'];

    let idFAnombreEmpresa = this.genericService.findFAWithTableField(1, "vivienda_comodin", "url_empresa", structure["id_functional_parent_initial_dsb"]);
    if(idFAnombreEmpresa && idFAnombreEmpresa['tmp_value']) nombreEmpresa = idFAnombreEmpresa['tmp_value'];

    let idFAEmpresa = this.genericService.findFAWithTableField(1, "vivienda_comodin", "id_empresa", structure["id_functional_parent_initial_dsb"]);
    if(idFAEmpresa && idFAEmpresa['tmp_value']) idEmpresa = idFAEmpresa['tmp_value'];
        
    if(posInm == -1) {
      if(esPresupuesto == 1) {
        if(this.genericService.findFAWithTableField(1, "clientes_oportunidades_comodin", "id_oportunidad", structure["id_functional_parent_initial_dsb"]) && this.genericService.findFAWithTableField(1, "clientes_oportunidades_comodin", "id_oportunidad", structure["id_functional_parent_initial_dsb"])['tmp_value']){
          idPresupuesto = this.genericService.findFAWithTableField(1, "clientes_oportunidades_comodin", "id_oportunidad", structure["id_functional_parent_initial_dsb"])['tmp_value']
        }
        if(this.genericService.findFAWithTableField(1, "clientes_oportunidades", "id_oportunidad", structure["id_functional_parent_initial_dsb"]) && this.genericService.findFAWithTableField(1, "clientes_oportunidades", "id_oportunidad", structure["id_functional_parent_initial_dsb"])['tmp_value']) {
          idPresupuesto = this.genericService.findFAWithTableField(1, "clientes_oportunidades", "id_oportunidad", structure["id_functional_parent_initial_dsb"])['tmp_value'];
        } 
        nombrePlantilla = "presupuesto-services";
      }
      else {
        idVivienda = this.genericService.findFAWithTableField(1, "vivienda", "id", structure["id_functional_parent_initial_dsb"])['tmp_value'];
        refInmo = this.genericService.findFAWithTableField(1, "vivienda", "referencia_inmo", structure["id_functional_parent_initial_dsb"])['tmp_value'];
        nameInmo = this.genericService.findFAWithTableField(1, "vivienda", "nombre_vivienda_inmo", structure["id_functional_parent_initial_dsb"])['tmp_value'];
      }
    }
    else {
      if(esPresupuesto == 1) {
        let idFAPresupuesto = this.genericService.findFAWithTableField(1, "clientes_oportunidades_comodin", "id_oportunidad", structure["id_functional_parent_initial_dsb"]);
        idPresupuesto = idFAPresupuesto['tmp_value'][posInm];
        nombrePlantilla = "presupuesto-services";
      }
      else {
        let idFAVivienda = this.genericService.findFAWithTableField(1, "vivienda", "id", structure["id_functional_parent_initial_dsb"]);
        let result = this.genericService.getValuesQuerySelection(idFAVivienda)[posInm];
        idVivienda = idFAVivienda['tmp_value'][posInm];
        refInmo = result.referencia_inmo;
        nameInmo = result.nombre_vivienda_inmo;
      }
    }
    
    if (nombreEmpresa == null && this.authService.companyGenericName) {
      nombreEmpresa = this.authService.companyGenericName;
    }
    if ((idEmpresa == null || idEmpresa == 0) && this.authService.empresaId) {
      idEmpresa = this.authService.empresaId;
    }

    let url = "";

    if(esPresupuesto == 0) {
      url = Values.ROOT_URL +
      "/" +
      idLanguage +
      "/" +
      nombreEmpresa +
      "-ref" +
      refInmo + "-" + nameInmo +
      "/" +
      nombrePlantilla +
      "/sth/" +
      idEmpresa +
      "&&" +
      idVivienda +
      "&&0";
    }
    else {
      url = Values.ROOT_URL +
      "/" +
      idLanguage +
      "/" +
      nombreEmpresa +
      "/" +
      nombrePlantilla +
      "/sth/" +
      idEmpresa +
      "&&" +
      idPresupuesto;
    }

    return url.replace(/ /g, "-");
  }

  sendWhatsAppClienteContactar(structure, param, finished, compartirUnoSolo = 0) {
    let validReq = this.functionsService.formsArray(structure, param);
    let addInmueble = false;
    let values = JSON.parse(JSON.stringify(this.functionsService.tmpArray));
    let nTelefono = null;
    let nPrefijo = null;
    let mensaje = null;
    let numInm = 0;
    let esCotizacion = 0;
    let esPresupuesto = 0;
    if (validReq) {

      for (let i in values) {
        if (values[i]["database"] == 1 && values[i]["table"] == "whatsapp_cliente_comodin" && values[i]["field"] == "telefono") {
          nTelefono = values[i]["value"];
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "whatsapp_cliente_comodin" && values[i]["field"] == "prefijo_pais") {
          nPrefijo = values[i]["value"];
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "whatsapp_cliente_comodin" && values[i]["field"] == "mensaje") {
          mensaje = values[i]["value"];
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "whatsapp_contactar_comodin" && values[i]["field"] == "telefono") {
          nTelefono = values[i]["value"];
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "whatsapp_contactar_comodin" && values[i]["field"] == "prefijo_pais") {
          nPrefijo = values[i]["value"];
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "whatsapp_contactar_comodin" && values[i]["field"] == "mensaje") {
          mensaje = values[i]["value"];
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "actividad_comodin" && values[i]["field"] == "es_actividad") {
          this.functionsService.insertUpdateForm(structure, param, finished, 1, false, false);
        }
        if (compartirUnoSolo == 0) {
          if (values[i]["database"] == 1 && values[i]["table"] == "vivienda" && values[i]["field"] == "id" && values[i]["value"] && values[i]["value"][0] > 0) {
            addInmueble = true;
            numInm = values[i]["value"].length;
          }
          if (values[i]["database"] == 1 && (values[i]["table"] == "clientes_oportunidades_comodin" || values[i]["table"] == "clientes_oportunidades") && values[i]["field"] == "id_oportunidad" && values[i]["value"] && values[i]["value"][0] > 0) {
            addInmueble = true;
            numInm = values[i]["value"].length;
            esPresupuesto = 1;
          }
        }
        else {
          if (values[i]["database"] == 1 && values[i]["table"] == "vivienda" && values[i]["field"] == "id" && values[i]["value"] && values[i]["value"] > 0) {
            addInmueble = true;
          }
          if (values[i]["database"] == 1 && values[i]["table"] == "gest_log_cotizaciones" && values[i]["field"] == "id_cotizacion" && values[i]["value"] && values[i]["value"] > 0) {
            addInmueble = true;
            esCotizacion = 1;
          }
          if (values[i]["database"] == 1 && (values[i]["table"] == "clientes_oportunidades_comodin" || values[i]["table"] == "clientes_oportunidades") && values[i]["field"] == "id_oportunidad" && values[i]["value"] && values[i]["value"] > 0) {
            addInmueble = true;
            esPresupuesto = 1;
          }
        }
      }

      mensaje = mensaje.replace(/(?:\r\n|\r|\n)/g, "%0A"); // Saltos de pagina whatsApp
      mensaje = mensaje.replace(/&/g, "%26"); // & whatsApp
      mensaje = mensaje.replaceAll("+", "%2B"); // + whatsApp
      mensaje = mensaje.replaceAll("#", "%23"); // # whatsApp

      let url = "";
      if (addInmueble) {
        if (compartirUnoSolo == 1) {
          if (esCotizacion == 1) {
            url = this.buildURLFichaCotizacion(-1);
            url = encodeURIComponent(url);
            mensaje += "%0A%0A" + url; // Juntamos mensaje y url
            url = "";
          } else {
            url = this.buildURLFichaInmueble(structure, -1, esPresupuesto);
            url = encodeURIComponent(url);
            mensaje += "%0A%0A" + url; // Juntamos mensaje y url
            url = "";
          }
        }
        else {
          for (let j = 0; j < numInm; j++) {
            url = this.buildURLFichaInmueble(structure, j, esPresupuesto);
            url = encodeURIComponent(url);
            mensaje += "%0A%0A" + url; // Juntamos mensaje y url
            url = "";
          }
        }
      }

      if (!nPrefijo) nPrefijo = "";
      let urlWhats = "https://wa.me/" + nPrefijo + nTelefono + "?text=" + mensaje;
      window.open(urlWhats);
      this.genericService.finishFunction(finished);
    } else {
      this.genericService.finishFunction(finished);
      // Joan: Warning després de l'upgrade de 9 a 14. Ho canvio
      //return new EmptyObservable<Response>();
      return EMPTY;
    }
  }

  compartirInmueble(structure, param, finished, compartirUnoSolo = 0) {
    let idFAContent = this.genericService.findFAWithTableField(1, "send_buffer_email", "content", structure["id_functional_parent_initial_dsb"]);
    let validReq = this.functionsService.formsArray(structure, param, false);
    let values = JSON.parse(JSON.stringify(this.functionsService.tmpArray));
    let mensaje = "";
    let numInm = 0;
    let esCotizacion = 0;
    let esPresupuesto = 0;

    if (validReq) {
      for (let i in values) {
        if (values[i]["database"] == 1 && values[i]["table"] == "send_buffer_email" && values[i]["field"] == "content" && values[i]["value"]) {
          mensaje = values[i]["value"];
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "vivienda" && values[i]["field"] == "id" && values[i]["value"] && values[i]["value"][0] > 0) {
          numInm = values[i]["value"].length;
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "gest_log_cotizaciones" && values[i]["field"] == "id_cotizacion" && values[i]["value"] && values[i]["value"][0] > 0) {
          esCotizacion = 1;
        }
        if (values[i]["database"] == 1 && (values[i]["table"] == "clientes_oportunidades_comodin" || values[i]["table"] == "clientes_oportunidades") && values[i]["field"] == "id_oportunidad" && values[i]["value"] && values[i]["value"][0] > 0) {
          numInm = values[i]["value"].length;
          esPresupuesto = 1;
        }
        if (values[i]["database"] == 1 && (values[i]["table"] == "clientes_oportunidades_comodin" || values[i]["table"] == "clientes_oportunidades") && values[i]["field"] == "id_oportunidad" && values[i]["value"] && values[i]["value"] > 0) {
          esPresupuesto = 1;
        }
      }
      let buttonStyle = "background: " + this.authService.primary_color + "; color: #fff; padding: 10px; border-radius: 3px; text-decoration: none;";
      if (mensaje != null) {
        if (compartirUnoSolo == 1) {
          if (esCotizacion == 1) {
            let link = this.buildURLFichaCotizacion(-1);
            mensaje += ' <br><br><a style="' + buttonStyle + '" href="' + link + '" target="_blank">Ver ficha cotización</a>';

          } else {
            let link = this.buildURLFichaInmueble(structure, -1, esPresupuesto);
            if(esPresupuesto == 1) mensaje += ' <br><br><a style="' + buttonStyle + '" href="' + link + '" target="_blank">Ver presupuesto</a>';
            else mensaje += ' <br><br><a style="' + buttonStyle + '" href="' + link + '" target="_blank">Ver ficha inmueble</a>';
          }
        }
        else {
          for (let j = 0; j < numInm; j++) {
            let link = this.buildURLFichaInmueble(structure, j, esPresupuesto);
            if(esPresupuesto == 1) mensaje += ' <br><br><a style="' + buttonStyle + '" href="' + link + '" target="_blank">Ver presupuesto</a>';
            else mensaje += ' <br><br><a style="' + buttonStyle + '" href="' + link + '" target="_blank">Ver ficha inmueble</a>';
          }
        }
        this.functionsService.assignValueFunctionalArea(idFAContent, mensaje);
        setTimeout(() => {
          this.functionsService.insertUpdateForm(structure, param, finished, 4);
        }, 500);
      }
    }
  }

  oneStepPayment(structure, param, finished, method = 5) {
    let idPedido = null;
    let propina = 0;
    let importe_pagado = 0;
    let importe_ticket = 0;
    let idEmpresa = this.authService.getIdEmpresa();
    let idTicket = null;
    let idCreador = null;
    let imprime = null;

    let intern_data = this.genericService.getInternParam(structure, param);
    for (let i in intern_data) {
      if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "rest_pedidos" && intern_data[i]["bd_field"] == "id_pedido" && intern_data[i]["value"] && intern_data[i]["value"] > 0) {
        idPedido = intern_data[i]["value"];
      }
      if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "rest_pedidos_tickets" && intern_data[i]["bd_field"] == "propina" && intern_data[i]["value"]) {
        propina = this.functionsService.cloneVariable(intern_data[i]["value"]).toString().replace(',', '.');
      }
      if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "comodin_rest_pedidos" && intern_data[i]["bd_field"] == "comodin_importe_pago" && intern_data[i]["value"]) {
        importe_pagado = this.functionsService.cloneVariable(intern_data[i]["value"]).toString().replace(',', '.');
      }
      if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "comodin_rest_pedidos" && intern_data[i]["bd_field"] == "comodin_importe_total" && intern_data[i]["value"]) {
        importe_ticket = this.functionsService.cloneVariable(intern_data[i]["value"]).toString().replace(',', '.');
      }
      if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "rest_pedidos" && intern_data[i]["bd_field"] == "id_creador" && intern_data[i]["value"] && intern_data[i]["value"] > 0) {
        idCreador = intern_data[i]["value"];
      }
      if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "rest_pedidos_comodin" && intern_data[i]["bd_field"] == "rest_imprimir_comodin" && intern_data[i]["value"]) {
        imprime = intern_data[i]["value"];
      }
    }

    if (idPedido > 0) {
      this.endpointClientService.insertTicketPedido(idPedido, method, propina, idEmpresa, idCreador, importe_pagado, importe_ticket).subscribe((data) => {
        this.genericService.finishFunction(finished);
        if (data['response']) idTicket = data['response'];
        //Error id_ticket = -2 --> no hay ninguna caja abierta
        if (idTicket == null || idTicket == -1) {
          this.genericService.openSnackBar("Parece que el cobro no se ha podido procesar", 4000, ["lightred-snackbar"]);
        }
        else if (idTicket == -2) {
          this.genericService.openSnackBar("No hay cajas abiertas", 4000, ["lightred-snackbar"]);
        }
        else if (idTicket == -3) {
          this.genericService.openSnackBar("El importe pagado debe ser superior al importe del ticket", 4000, ["lightred-snackbar"]);
        }
        else {
          if (imprime == 1) {
            this.printTicketService.printTicketRestaurant(idTicket); // TODO ALEIX: Solo si la empresa lo quiere
          }
          this.openCashRest();
          this.functionsService.route = structure["internal_routing"];
          this.functionsService.id_route = structure["internal_routing_id_functional_area"];
          this.routingService.previousModule = structure["id_functional_parent_initial"];
          this.functionsService.goRouting(this.functionsService.route, true);
        }
      });
    }
    else this.genericService.openSnackBar("No se ha encontrado ningún pedido a imprimir", 4000, ["lightred-snackbar"]);
  }

  public changeHideButton(structure) {
    let idFA = null;
    let val = structure['tmp_value'];

    if (typeof val === "string" && val.includes(",")) {
        val = this.functionsService.cloneVariable(val).replaceAll(",", ".");
    }
    val = +val;
    
    console.log("splittedComma", structure["internal_routing_id_functional_area"]);
    let splittedComma = structure["internal_routing_id_functional_area"].split(",");
    
    // El primer valor de la coma es el campo que se busca para rellenar
    // El segundo valor de la coma es el caso de hide

    for( let i in splittedComma) {
      let splittedHyphen = splittedComma[i].split("-");

      switch (splittedHyphen[1]) {
        case "1":
          idFA = this.genericService.findElementWithId(splittedComma[i], false, false, true);
          if (idFA !== null) {
            idFA['hide'] = (!val || val <= 0) ? 1 : 0;
            this.functionsService.changeHide0(idFA);
          }
          break;
        case "2":
            idFA = this.genericService.findElementWithId(splittedComma[i], false, false, true);
            if (idFA !== null) {
                let val2 = 0;
                if (idFA['tmp_value'] !== undefined) {
                  val2 = this.functionsService.cloneVariable(idFA['tmp_value']).replaceAll(",", ".");
                }
                idFA['hide'] = (val <= 0 || val2 > 0) ? 1 : 0;
                this.functionsService.changeHide0(idFA);
            }
          break;
      }
    }
  }

  public cambiarEmpresa(structure, id, idEmpresa, idEmpresaOld) {
    this.endpointClientService.cambiarEmpresa(id, structure["internal_routing"], idEmpresa, idEmpresaOld).subscribe(data => {
      if (data["errorMessage"] == "Se ha cambiado de cuenta") {
        this.functionsService.snackBar.open(data["errorMessage"], "x", {
          duration: 6000,
          panelClass: ["green-snackbar"],
        });
      }
      else {
        this.functionsService.snackBar.open(data["errorMessage"], "x", {
          duration: 6000,
          panelClass: ["red-snackbar"],
        });
      }
    })
  }

  public updateImagesProperty(finished) {
    this.endpointClientService
      .updateImagesProperty(this.functionsService.arrayImagenes.flat())
      .subscribe((data) => {
        finished = true;
        this.functionsService.openSnackBar("Cambios guardados correctamente", 15000, [
          "green-snackbar",
        ]);
        // this.functionsService.refreshStructure(0); --> Ya no se pone porque ahora es todo desde popups
      });
  }

  public duplicarInteres(structure, idInteres) {
    this.endpointClientService.duplicarInteres(idInteres).subscribe(data => {
      this.functionsService.snackBar.open(data["errorMessage"], "x", {
        duration: 6000,
        panelClass: ["green-snackbar"],
      });
      console.log(structure, structure["internal_routing"] !== undefined, structure["internal_routing"] !== "", structure["internal_routing"] != null);
      if (structure["internal_routing"] !== undefined && structure["internal_routing"] !== "" && structure["internal_routing"] != null) {
        this.functionsService.addWindowParam(data["response"], 1, "interes_cliente", "id", structure['id_functional_parent_initial'], true, "output");
        this.functionsService.route = structure["internal_routing"];
        this.functionsService.id_route = structure["internal_routing_id_functional_area"];
        this.routingService.previousModule = structure["id_functional_parent_initial"];
        this.functionsService.goRouting(this.functionsService.route, false);
      } else {
        this.functionsService.addWindowParam(data["response"], 1, "interes_cliente", "id", structure['id_functional_parent_initial'], true, "input");
        this.functionsService.refreshStructure();
      }
    })
  }

  public checkAllPersonsHotelStatic(structure, param, finished) {
    // Num personas
    let numAdultosFiltroFA = this.functionsService.findFAWithTableField(1, "hotel_reservas", "num_adultos_habitacion", structure["id_functional_parent_initial_dsb"]);
    let numAdultosFiltro = 0;
    if(numAdultosFiltroFA && numAdultosFiltroFA['tmp_value']) {
      numAdultosFiltro = +numAdultosFiltroFA['tmp_value'];
    }

    let numNinosFiltroFA = this.functionsService.findFAWithTableField(1, "hotel_reservas", "num_ninos_habitacion", structure["id_functional_parent_initial_dsb"]);
    let numNinosFiltro = 0;
    if(numNinosFiltroFA && numNinosFiltroFA['tmp_value']) {
      numNinosFiltro = +numNinosFiltroFA['tmp_value'];
    }
    
    let numBebesFiltroFA = this.functionsService.findFAWithTableField(1, "hotel_reservas", "num_bebes_habitacion", structure["id_functional_parent_initial_dsb"]);
    let numBebesFiltro = 0;
    if(numBebesFiltroFA && numBebesFiltroFA['tmp_value']) {
      numBebesFiltro = +numBebesFiltroFA['tmp_value'];
    }

    let numAdultosTotal = 0;
    let numNinosTotal = 0;
    let numBebesTotal = 0;
    for(let i in this.authService.arrayEstancias) {
      numAdultosTotal += +this.authService.arrayEstancias[i]['adultos'];
      numNinosTotal += +this.authService.arrayEstancias[i]['ninos'];
      numBebesTotal += +this.authService.arrayEstancias[i]['bebes'];
    }

    let idStep = 1004;
    if(numAdultosTotal != numAdultosFiltro) {
      this.functionsService.openSnackBar(this.genericService.staticTexts[17], 5000, ["red-snackbar"], "center", "top");
      this.genericService.openStep(idStep);
    }
    else if(numNinosTotal != numNinosFiltro) {
      this.functionsService.openSnackBar(this.genericService.staticTexts[16], 5000, ["red-snackbar"], "center", "top");
      this.genericService.openStep(idStep);
    }
    else if(numBebesTotal != numBebesFiltro) {
      this.functionsService.openSnackBar(this.genericService.staticTexts[15], 5000, ["red-snackbar"], "center", "top");
      this.genericService.openStep(idStep);
    }
    else {
      this.functionsService.assignPaymentImport(this.authService.estanciasPriceCounter + this.authService.actividadesPriceCounter);
      this.functionsService.makePayment(structure, param, finished);
    }
    console.log("numPersonas", numAdultosTotal, numNinosTotal, numBebesTotal, numAdultosFiltro, numNinosFiltro, numBebesFiltro);
  }

  public manageActividadesHotelStatic(structure) {
    let actualNgForId = this.functionsService.getNgForId(structure);
    let errorMessage = null;

    setTimeout(() => {
      // Num noches de estancia
      let fechaInicio = this.functionsService.findFAWithTableField(1, "hotel_reservas", "fecha_entrada", structure["id_functional_parent_initial_dsb"])['tmp_value'];
      let fechaFin = this.functionsService.findFAWithTableField(1, "hotel_reservas", "fecha_salida", structure["id_functional_parent_initial_dsb"])['tmp_value'];
      if(fechaInicio && fechaFin) {
        let date1 = new Date(fechaInicio);
        let date2 = new Date(fechaFin);
        let diffTime = Math.abs(date2.getTime() - date1.getTime());
        this.authService.daysCounter = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) - 1;
        console.log("numNoches", this.authService.daysCounter);
      } else {
        this.functionsService.openSnackBar(this.genericService.staticTexts[18], 5000, ["red-snackbar"], "center", "top");
        return;
      }

      // Num personas
      let numAdultosField = this.functionsService.findFAWithTableField(1, "hotel_actividades_reserva", "num_adultos", structure["id_functional_parent_initial_dsb"], actualNgForId, false);
      let numAdultos = 0;
      if(numAdultosField && numAdultosField['tmp_value']) {
        numAdultos = +numAdultosField['tmp_value'];
      }

      let numNinosField = this.functionsService.findFAWithTableField(1, "hotel_actividades_reserva", "num_ninos", structure["id_functional_parent_initial_dsb"], actualNgForId, false);
      let numNinos = 0;
      if(numNinosField && numNinosField['tmp_value']) {
        numNinos = +numNinosField['tmp_value'];
      }
      
      let numBebesField = this.functionsService.findFAWithTableField(1, "hotel_actividades_reserva", "num_bebes", structure["id_functional_parent_initial_dsb"], actualNgForId, false);
      let numBebes = 0;
      if(numBebesField && numBebesField['tmp_value']) {
        numBebes = +numBebesField['tmp_value'];
      }

      let numNochesField = this.functionsService.findFAWithTableField(1, "hotel_actividades_reserva", "num_noches", structure["id_functional_parent_initial_dsb"], actualNgForId, false);
      let numNoches = 0;
      if(numNochesField && numNochesField['tmp_value']) {
        numNoches = +numNochesField['tmp_value'];
      }
      console.log("numPersonas", numAdultos, numNinos, numBebes);

      // Definir maximo de personas
      let ocupacionMaxima = this.functionsService.findFAWithTableField(1, "hotel_actividades", "num_ocupantes_maximo", structure["id_functional_parent_initial_dsb"], actualNgForId, false)['tmp_value'];

      // Limite ocupacion
      if((numAdultos + numNinos + numBebes) > ocupacionMaxima) {
        console.log("limitPersonas", numAdultos + numNinos + numBebes, ocupacionMaxima);
        errorMessage = this.genericService.staticTexts[19];
        this.functionsService.openSnackBar(errorMessage, 5000, ["red-snackbar"], "center", "top");

        let value = 0;
        if(structure["bd_field"] == "num_adultos") {
          value = ocupacionMaxima - numNinos - numBebes;
        }
        if(structure["bd_field"] == "num_ninos") {
          value = ocupacionMaxima - numAdultos - numBebes;
        }
        if(structure["bd_field"] == "num_bebes") {
          value = ocupacionMaxima - numAdultos - numNinos;
        }
        
        let FAToLimit = this.functionsService.findFunctionalArea(structure, structure["id_functional_area"]);
        this.functionsService.assignValueFunctionalArea(FAToLimit, value);
      }
      
      // Precios
      this.calcPrecioActividadHotelStatic(structure, actualNgForId, numAdultos, numNinos, numBebes, numNoches);
    });
  }

  public calcPrecioActividadHotelStatic(structure, actualNgForId, numAdultos, numNinos, numBebes, numNoches) {
    let disponibilidad = this.functionsService.findFAWithTableField(1, "hotel_actividades", "disponibilidad", structure["id_functional_parent_initial_dsb"], actualNgForId, false)['tmp_value'];

    let precioUnitario = this.functionsService.findFAWithTableField(1, "hotel_actividades", "comodin_precio_unitario", structure["id_functional_parent_initial_dsb"], actualNgForId, false)['tmp_value'];
    let precioAdulto = this.functionsService.findFAWithTableField(1, "hotel_actividades", "comodin_precio_adulto", structure["id_functional_parent_initial_dsb"], actualNgForId, false)['tmp_value'];
    let precioNino = this.functionsService.findFAWithTableField(1, "hotel_actividades", "comodin_precio_nino", structure["id_functional_parent_initial_dsb"], actualNgForId, false)['tmp_value'];
    let precioBebe = this.functionsService.findFAWithTableField(1, "hotel_actividades", "comodin_precio_bebe", structure["id_functional_parent_initial_dsb"], actualNgForId, false)['tmp_value'];
    
    let descuento = this.functionsService.findFAWithTableField(1, "hotel_actividades", "comodin_descuento", structure["id_functional_parent_initial_dsb"], actualNgForId, false)['tmp_value'];
    let tipoDescuento = this.functionsService.findFAWithTableField(1, "hotel_actividades", "comodin_descuento_unidad", structure["id_functional_parent_initial_dsb"], actualNgForId, false)['tmp_value'];
    
    let precio = 0;

    let precioAdultoVal = parseFloat(precioAdulto.replace(',', '.')) || 0;
    let precioNinoVal = parseFloat(precioNino.replace(',', '.')) || 0;
    let precioBebeVal = parseFloat(precioBebe.replace(',', '.')) || 0;

    switch (disponibilidad) {
      case 1:
        // Por persona (ilimitado)
        precio = (precioAdultoVal * numAdultos)  + 
                  (precioNinoVal * numNinos) + 
                  (precioBebeVal * numBebes);

        console.log("precios", precio, precioAdulto, precioNino, precioBebe);
        break;
      case 2:
        // Uno por noche por persona
        precio = ((precioAdultoVal * numAdultos)  + 
                  (precioNinoVal * numNinos) + 
                  (precioBebeVal * numBebes)) * numNoches;
        break;
      case 3:
        // N por reserva (1)
        precio = parseFloat(precioUnitario.replace(',', '.')) * numAdultos;
        break;
    }

    if (descuento) descuento = parseFloat(descuento.replace(',', '.'));

    if (descuento > 0) {
      if (tipoDescuento == "€") {
        precio = precio - descuento;
        if(precio < 0) precio = 0;
      } else if (tipoDescuento == "%") {
        precio = precio - (precio * descuento / 100);
      }
    }

    // Actualizar precio
    let importeActividadFA = this.functionsService.findFAWithTableField(1, "hotel_actividades_reserva", "importe", structure["id_functional_parent_initial_dsb"], actualNgForId, false);
    let importeAnterior = importeActividadFA['tmp_value'] !== undefined ? importeActividadFA['tmp_value'] : 0;
    this.authService.actividadesPriceCounter += precio - importeAnterior;
    this.authService.actividadesPriceCounterLabel = this.functionsService.cloneVariable(this.authService.actividadesPriceCounter).toString().replace(".", ",");
    this.functionsService.assignValueFunctionalArea(importeActividadFA, precio);
  }

  public manageEstanciasHotelStatic(structure) {
    let actualNgForId = this.functionsService.getNgForId(structure);
    let errorMessage = null;

    setTimeout(() => {

      // Num noches de estancia
      let fechaInicio = this.functionsService.findFAWithTableField(1, "hotel_reservas", "fecha_entrada", structure["id_functional_parent_initial_dsb"])['tmp_value'];
      let fechaFin = this.functionsService.findFAWithTableField(1, "hotel_reservas", "fecha_salida", structure["id_functional_parent_initial_dsb"])['tmp_value'];
      if(fechaInicio && fechaFin) {
        let date1 = new Date(fechaInicio);
        let date2 = new Date(fechaFin);
        let diffTime = Math.abs(date2.getTime() - date1.getTime());
        this.authService.daysCounter = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) - 1;
        console.log("numNoches", this.authService.daysCounter);
      } else {
        this.functionsService.openSnackBar(this.genericService.staticTexts[18], 5000, ["red-snackbar"], "center", "top");
        return;
      }

      // Estancia seleccionada
      let room = this.functionsService.findFAWithTableField(1, "comodin_hotel_estancias_tipo", "id_tipo_estancia", structure["id_functional_parent_initial_dsb"], actualNgForId, false)['tmp_value'];
      let name = this.functionsService.findFAWithTableField(1, "comodin_hotel_estancias_tipo", "nombre", structure["id_functional_parent_initial_dsb"], actualNgForId, false)['tmp_value'];

      // Num personas
      let numAdultosField = this.functionsService.findFAWithTableField(1, "hotel_reservas_estancias_tipo", "num_adultos", structure["id_functional_parent_initial_dsb"], actualNgForId, false);
      let numAdultos = 0;
      if(numAdultosField && numAdultosField['tmp_value']) numAdultos = +numAdultosField['tmp_value'];
      let numNinosField = this.functionsService.findFAWithTableField(1, "hotel_reservas_estancias_tipo", "num_ninos", structure["id_functional_parent_initial_dsb"], actualNgForId, false);
      let numNinos = 0;
      if(numNinosField && numNinosField['tmp_value']) numNinos = +numNinosField['tmp_value'];
      let numBebesField = this.functionsService.findFAWithTableField(1, "hotel_reservas_estancias_tipo", "num_bebes", structure["id_functional_parent_initial_dsb"], actualNgForId, false);
      let numBebes = 0;
      if(numBebesField && numBebesField['tmp_value']) numBebes = +numBebesField['tmp_value'];
      console.log("numPersonas", numAdultos, numNinos, numBebes);
      
      // Cantidad de estancia seleccionada y cantidad de estancia anterior
      let structureCantidad = this.functionsService.findFAWithTableField(1, "hotel_reservas_estancias_tipo", "cantidad", structure["id_functional_parent_initial_dsb"], actualNgForId, false);
      let numEstancias = structureCantidad['tmp_value'] !== undefined ? structureCantidad['tmp_value'] : 0;
      let numEstanciasLast = structureCantidad['tmp_value_old'] !== undefined ? structureCantidad['tmp_value_old'] : 0;
      console.log("numEstancias", numEstancias, numEstanciasLast);

      // Valores en la base de datos
      let totalBookingNumAdulos = +this.functionsService.findFAWithTableField(1, "hotel_reservas", "num_adultos_habitacion", structure["id_functional_parent_initial_dsb"])['tmp_value'];
      let totalBookingNumNinos = +this.functionsService.findFAWithTableField(1, "hotel_reservas", "num_ninos_habitacion", structure["id_functional_parent_initial_dsb"])['tmp_value'];
      let totalBookingNumBebes = +this.functionsService.findFAWithTableField(1, "hotel_reservas", "num_bebes_habitacion", structure["id_functional_parent_initial_dsb"])['tmp_value'];
      console.log("totalBooking", totalBookingNumAdulos, totalBookingNumNinos, totalBookingNumBebes);

      let numAdultosTotal = 0;
      let numNinosTotal = 0;
      let numBebesTotal = 0;
      for(let i in this.authService.arrayEstancias) {
        if(this.authService.arrayEstancias[i]['nombre'] != name) {
          numAdultosTotal += +this.authService.arrayEstancias[i]['adultos'];
          numNinosTotal += +this.authService.arrayEstancias[i]['ninos'];
          numBebesTotal += +this.authService.arrayEstancias[i]['bebes'];
        }
      }
      console.log("totalPersonasOtrasEstancias", numAdultosTotal, numNinosTotal, numBebesTotal);

      if(totalBookingNumAdulos < (numAdultosTotal + numAdultos)) {
        let maxAdultsAvailable = totalBookingNumAdulos - numAdultosTotal;
        errorMessage = this.genericService.staticTexts[20] + maxAdultsAvailable + this.genericService.staticTexts[21];
        structure['hasErrorEstanciasAnterior'] = true;
        this.functionsService.assignValueFunctionalArea(numAdultosField, maxAdultsAvailable);
        this.functionsService.openSnackBar(errorMessage, 10000, ["blue-snackbar"], "center", "top");
        return;
      }
      if(totalBookingNumNinos < (numNinosTotal + numNinos)) {
        let maxNinosAvailable = totalBookingNumNinos - numNinosTotal;
        errorMessage = this.genericService.staticTexts[23] + maxNinosAvailable + this.genericService.staticTexts[24];
        structure['hasErrorEstanciasAnterior'] = true;
        this.functionsService.assignValueFunctionalArea(numNinosField, maxNinosAvailable);
        this.functionsService.openSnackBar(errorMessage, 10000, ["blue-snackbar"], "center", "top");
        return;
      }
      if(totalBookingNumBebes < (numBebesTotal + numBebes)) {
        let maxBebesAvailable = totalBookingNumBebes - numBebesTotal;
        errorMessage = this.genericService.staticTexts[25] + maxBebesAvailable + this.genericService.staticTexts[26] ;
        structure['hasErrorEstanciasAnterior'] = true;
        this.functionsService.assignValueFunctionalArea(numBebesField, maxBebesAvailable);
        this.functionsService.openSnackBar(errorMessage, 10000, ["blue-snackbar"], "center", "top");
        return;
      }

      // Definir maximos
      let ocupacionMinima = this.functionsService.findFAWithTableField(1, "comodin_hotel_estancias_tipo", "ocupacion_minima", structure["id_functional_parent_initial_dsb"], actualNgForId, false)['tmp_value'];
      let ocupacionDefecto = this.functionsService.findFAWithTableField(1, "comodin_hotel_estancias_tipo", "ocupacion_por_defecto", structure["id_functional_parent_initial_dsb"], actualNgForId, false)['tmp_value'];
      let ocupacionMaxima = this.functionsService.findFAWithTableField(1, "comodin_hotel_estancias_tipo", "ocupacion_maxima", structure["id_functional_parent_initial_dsb"], actualNgForId, false)['tmp_value'];
      let ocupacionMaximaAdulto = this.functionsService.findFAWithTableField(1, "comodin_hotel_estancias_tipo", "ocupacion_maxima_adultos", structure["id_functional_parent_initial_dsb"], actualNgForId, false)['tmp_value'];
      let ocupacionMaximaNino = this.functionsService.findFAWithTableField(1, "comodin_hotel_estancias_tipo", "ocupacion_maxima_ninos", structure["id_functional_parent_initial_dsb"], actualNgForId, false)['tmp_value'];
      let ocupacionMaximaBebe = this.functionsService.findFAWithTableField(1, "comodin_hotel_estancias_tipo", "ocupacion_maxima_bebes", structure["id_functional_parent_initial_dsb"], actualNgForId, false)['tmp_value'];
      
      ocupacionMinima = numEstancias * ocupacionMinima;
      ocupacionDefecto = numEstancias * ocupacionDefecto;
      ocupacionMaxima = numEstancias * ocupacionMaxima;
      ocupacionMaximaAdulto = numEstancias * ocupacionMaximaAdulto;
      ocupacionMaximaNino = numEstancias * ocupacionMaximaNino;
      ocupacionMaximaBebe = numEstancias * ocupacionMaximaBebe;
      console.log("ocupacion", ocupacionMinima, ocupacionDefecto, ocupacionMaxima, ocupacionMaximaAdulto, ocupacionMaximaNino, ocupacionMaximaBebe);
      
      // Limites ocupacion
      if(numBebes > ocupacionMaximaBebe) {
        this.functionsService.assignValueFunctionalArea(numBebesField, ocupacionMaximaBebe);
        console.log("limitBebes", numBebes, ocupacionMaximaBebe);
        errorMessage = this.genericService.staticTexts[27];
        structure['hasErrorEstanciasAnterior'] = true;
        this.functionsService.openSnackBar(errorMessage, 5000, ["red-snackbar"], "center", "top");
        return;
      }
      if(numNinos > ocupacionMaximaNino) {
        this.functionsService.assignValueFunctionalArea(numNinosField, ocupacionMaximaNino);
        console.log("limitNinos", numNinos, ocupacionMaximaNino);
        errorMessage = this.genericService.staticTexts[28];
        structure['hasErrorEstanciasAnterior'] = true;
        this.functionsService.openSnackBar(errorMessage, 5000, ["red-snackbar"], "center", "top");
        return;
      }
      if(numAdultos > ocupacionMaximaAdulto) {
        this.functionsService.assignValueFunctionalArea(numAdultosField, ocupacionMaximaAdulto);
        console.log("limitAdultos", numAdultos, ocupacionMaximaAdulto);
        errorMessage = this.genericService.staticTexts[29];
        structure['hasErrorEstanciasAnterior'] = true;
        this.functionsService.openSnackBar(errorMessage, 5000, ["red-snackbar"], "center", "top");
        return;
      }
      if((numAdultos + numNinos + numBebes) > ocupacionMaxima) {
        console.log("limitPersonas", numAdultos + numNinos + numBebes, ocupacionMaxima);

      let value = 0;
        if(structure["bd_field"] == "num_adultos") {
          value = ocupacionMaxima - numNinos - numBebes;
        }
        if(structure["bd_field"] == "num_ninos") {
          value = ocupacionMaxima - numAdultos - numBebes;
        }
        if(structure["bd_field"] == "num_bebes") {
          value = ocupacionMaxima - numAdultos - numNinos;
        }
        
        let FAToLimit = this.functionsService.findFunctionalArea(structure, structure["id_functional_area"]);
        this.functionsService.assignValueFunctionalArea(FAToLimit, value);
        errorMessage = this.genericService.staticTexts[30];
        structure['hasErrorEstanciasAnterior'] = true;
        this.functionsService.openSnackBar(errorMessage, 5000, ["red-snackbar"], "center", "top");
        return;
      }
      if((numAdultos + numNinos + numBebes) < ocupacionMinima) {
        console.log("minPersonas", numAdultos + numNinos + numBebes, ocupacionMinima);
        errorMessage = this.genericService.staticTexts[31] + ocupacionMinima + this.genericService.staticTexts[32];
      }
      
      // Precios
      let precio = this.calcPrecioEstanciaHotelStatic(structure, actualNgForId, numEstancias, ocupacionDefecto, numAdultos, numNinos, numBebes);

      // Asignamos el precio a la estancia
      let precioUnidad = this.updatePriceEstanciaHotelStatic(structure, precio, numEstancias, actualNgForId);

      // Seleccion de estancia
      let estanciasMultiField = this.functionsService.findFAWithTableField(1, "comodin_hotel_reservas_estancias_tipo", "id_tipo_estancia", structure["id_functional_parent_initial_dsb"]);
      let estanciasMulti = estanciasMultiField['tmp_value'];

      if (room != null && precio != null) {
        // Actualizamos el "selection multiple"
        this.updateFieldArrayEstanciaHotelStatic(estanciasMulti, room, numEstancias, estanciasMultiField);
        
        // Actualizamos el array de estancias
        this.updateArrayEstanciaHotelStatic(name, numEstancias, numAdultos, numNinos, numBebes, precioUnidad, precio);
        
        // Cambiamos las variables de la reserva
        let lastEstanciasCounter = this.functionsService.cloneVariable(this.authService.estanciasCounter);
        let precioTotal = 0;
        let numEstanciasTotal = 0;
        for(let i in this.authService.arrayEstancias) {
          precioTotal += this.authService.arrayEstancias[i]['precioTotal'];
          numEstanciasTotal += +this.authService.arrayEstancias[i]['cantidad'];
        }    
        this.authService.estanciasPriceCounter = precioTotal;
        this.authService.estanciasCounter = numEstanciasTotal;
        this.authService.estanciasPriceCounterLabel = this.functionsService.cloneVariable(this.authService.estanciasPriceCounter).toString().replace(".", ",");
        
        let valDiff = this.authService.estanciasCounter - lastEstanciasCounter;
        if(structure['hasErrorEstanciasAnterior']) {
          structure['hasErrorEstanciasAnterior'] = false;
        } else {
          if(errorMessage) {
            this.functionsService.openSnackBar(errorMessage, 5000, ["red-snackbar"], "center", "top");
          } else {
            if (valDiff > 0) {
              this.functionsService.openSnackBar(this.genericService.staticTexts[33], 5000, ["green-snackbar"], "center", "top");
            } else if (valDiff < 0) {
              this.functionsService.openSnackBar(this.genericService.staticTexts[34], 5000, ["blue-snackbar"], "center", "top");
            } else {
              this.functionsService.openSnackBar(this.genericService.staticTexts[35], 5000, ["green-snackbar"], "center", "top");
            }
          }
        }

        // Actualizamos las variables de la reserva que decide si debe pasar de step o no
        this.updateCheckEstanciaHotelStatic(structure);
      }
    });
  }

  private calcPrecioEstanciaHotelStatic(structure: any, actualNgForId: any, numEstancias: any, ocupacionDefecto: any, numAdultos: any, numNinos: number, numBebes: number) {
    let precioOriginal = this.functionsService.findFAWithTableField(1, "comodin_hotel_estancias_tipo", "precio_base", structure["id_functional_parent_initial_dsb"], actualNgForId, false)['tmp_value'];
    let precioExtraAdulto = this.functionsService.findFAWithTableField(1, "comodin_hotel_estancias_tipo", "precio_huesped_extra", structure["id_functional_parent_initial_dsb"], actualNgForId, false)['tmp_value'];
    let precioExtraNino = this.functionsService.findFAWithTableField(1, "comodin_hotel_estancias_tipo", "precio_nino_extra", structure["id_functional_parent_initial_dsb"], actualNgForId, false)['tmp_value'];
    let precioExtraBebe = this.functionsService.findFAWithTableField(1, "comodin_hotel_estancias_tipo", "precio_bebe_extra", structure["id_functional_parent_initial_dsb"], actualNgForId, false)['tmp_value'];
    let applyAllDiscount = this.functionsService.findFAWithTableField(1, "comodin_hotel_estancias_tipo", "aplicable_extras", structure["id_functional_parent_initial_dsb"], actualNgForId, false);
    let descuento = this.functionsService.findFAWithTableField(1, "comodin_hotel_estancias_tipo", "descuento", structure["id_functional_parent_initial_dsb"], actualNgForId, false)['tmp_value'];
    let tipoDecuento = this.functionsService.findFAWithTableField(1, "comodin_hotel_estancias_tipo", "descuento_unidad", structure["id_functional_parent_initial_dsb"], actualNgForId, false)['tmp_value'];
    if(applyAllDiscount && applyAllDiscount['tmp_value']) applyAllDiscount = applyAllDiscount['tmp_value'];
    else applyAllDiscount = 0;

    let precio = parseFloat(precioOriginal.replace(',', '.')) * numEstancias * this.authService.daysCounter;
    precioExtraAdulto = parseFloat(precioExtraAdulto.replace(',', '.')) * this.authService.daysCounter;
    precioExtraNino = parseFloat(precioExtraNino.replace(',', '.')) * this.authService.daysCounter;
    precioExtraBebe = parseFloat(precioExtraBebe.replace(',', '.')) * this.authService.daysCounter;
    if (descuento) descuento = parseFloat(descuento.replace(',', '.'));
    console.log("preciosEstancia", precio, precioExtraAdulto, precioExtraNino, precioExtraBebe, descuento, tipoDecuento);

    let appliedDiscount = false;
    if (descuento > 0 && tipoDecuento == "%" && applyAllDiscount == 0) {
      precio = precio - (precio * descuento / 100);
      appliedDiscount = true;
    }
    
    if (ocupacionDefecto < numAdultos) { // Tenemos ocupacion por defecto ocupada por adultos (sobran adultos y quizás niños/bebés)
      let adultosExtra = numAdultos - ocupacionDefecto;
      console.log("Hay adultos extra: ", adultosExtra);
      precio = precio + precioExtraAdulto * adultosExtra + precioExtraNino * numNinos + precioExtraBebe * numBebes;
    } else if (ocupacionDefecto < (numAdultos + numNinos)) { // Tenemos ocupacion por defecto ocupada por adultos + niños (sobran niños y quizás bebés)
      let ninosExtra = (numAdultos + numNinos) - ocupacionDefecto;
      console.log("Hay niños extra: ", ninosExtra);
      precio = precio + precioExtraNino * ninosExtra + precioExtraBebe * numBebes;
    } else if (ocupacionDefecto < (numAdultos + numNinos + numBebes)) { // Tenemos ocupacion por defecto ocupada por adultos + niños + bebés (sobran bebés)
      let bebesExtra = (numAdultos + numNinos + numBebes) - ocupacionDefecto;
      console.log("Hay bebes extra: ", bebesExtra);
      precio = precio + precioExtraBebe * bebesExtra;
    }

    if (descuento > 0 && !appliedDiscount) {
      if (tipoDecuento == "€") {
        precio = precio - descuento;
        if(precio < 0) precio = 0;
      } else if (tipoDecuento == "%") {
        precio = precio - (precio * descuento / 100);
      }
    }
    console.log("precioEstanciaFinal", precio);
    return precio;
  }

  private updatePriceEstanciaHotelStatic(structure: any, precio: number, numEstancias: any, actualNgForId) {
    let precioTotalEstanciaField = this.functionsService.findFAWithTableField(1, "hotel_reservas_estancias_tipo", "precio_total", structure["id_functional_parent_initial_dsb"], actualNgForId, false);
    this.functionsService.assignValueFunctionalArea(precioTotalEstanciaField, precio);
    let precioUnidadEstanciaField = this.functionsService.findFAWithTableField(1, "hotel_reservas_estancias_tipo", "precio_unidad", structure["id_functional_parent_initial_dsb"], actualNgForId, false);
    let precioUnidad = Math.round((precio / numEstancias) * 100) / 100;
    this.functionsService.assignValueFunctionalArea(precioUnidadEstanciaField, precioUnidad);
    return precioUnidad;
  }

  private updateFieldArrayEstanciaHotelStatic(estanciasMulti: any, room: any, numEstancias: any, estanciasMultiField: any) {
    if (estanciasMulti && estanciasMulti.length && estanciasMulti.length > 0) {
      let founded = false;
      for (let i in estanciasMulti) {
        if (estanciasMulti[i] == room) {
          founded = true;
          if (numEstancias <= 0) {
            estanciasMulti.splice(i, 1);
          }
          break;
        }
      }
      if (!founded) {
        if (numEstancias > 0) {
          estanciasMulti.push(room);
        }
      }
    } else {
      if (numEstancias > 0) {
        estanciasMulti = [room];
      }
    }
    this.functionsService.assignValueFunctionalArea(estanciasMultiField, estanciasMulti);
  }

  private updateArrayEstanciaHotelStatic(name: any, numEstancias: any, numAdultos: any, numNinos: number, numBebes: number, precioUnidad: number, precio: number) {
    let objEstanciaReserva = { nombre: name, cantidad: numEstancias, adultos: numAdultos, ninos: numNinos, bebes: numBebes, precioUnidad: precioUnidad, precioTotal: precio };
    const index = this.authService.arrayEstancias.findIndex(estancia => estancia.nombre === name);
    if (index !== -1) {
      if (numEstancias <= 0) {
        this.authService.arrayEstancias.splice(index, 1);
      } else {
        this.authService.arrayEstancias[index] = objEstanciaReserva;
      }
    } else {
      if (numEstancias > 0) this.authService.arrayEstancias.push(objEstanciaReserva);
    }
    this.authService.arrayEstanciasLabel = this.authService.arrayEstancias.join('<br>');
  }

  private updateCheckEstanciaHotelStatic(structure: any) {
    let hasEstancias = "";
    let fieldCheckHasEstancias = this.functionsService.findFAWithTableField(1, "comodin_check_has_estancias", "comodin_check_has_estancias", structure["id_functional_parent_initial_dsb"]);
    if (this.authService.arrayEstancias && this.authService.arrayEstancias.length && this.authService.arrayEstancias.length > 0) {
      hasEstancias = "1";
    }
    this.functionsService.assignValueFunctionalArea(fieldCheckHasEstancias, hasEstancias);
  }

  public openColorPickerDialog(param): void {
    let panelClass = [];
    if (this.routingService.shouldOpenExternalWindow) panelClass.push("overAllDialog");
    const dialogRef = this.functionsService.dialog.open(ColorPickerDialog, {
      width: "auto",
      height: "auto",
      data: param.type,
      autoFocus: true,
      panelClass: panelClass,
    });
    dialogRef.afterClosed().subscribe((result) => {
      // type is null => add new category
      if (param.type == null && result != null) {
        const name = result["name"];
        const color = result["color"];
        const isPrivate = result["isPrivate"];
        const signable = result["signable"];
        const portalVisitCompatibility = result["portalVisitCompatibility"];
        const profileId = this.authService.getLoggedInUserId();
        this.endpointClientService
          .addNewCategoryType(
            {
              name: name,
              color: color,
              isPrivate: isPrivate,
              signable: signable,
              portalVisitCompatibility: portalVisitCompatibility,
            },
            profileId,
            this.authService.returnActiveAccountId(),
            this.authService.returnActiveAccountType()
          )
          .subscribe((data) => {
            this.endpointClientService
              .getCategories(
                profileId,
                this.authService.returnActiveAccountId(),
                this.authService.returnActiveAccountType(),
                this.authService.isOrphan
              )
              .subscribe((data_cat) => {
                setCategories(data_cat);
                this.genericService.openSnackBar("Categorí­a añadida", 6000, [
                  "green-snackbar",
                ]);
              });
          });
      } else if (param.type != null && result != null) {
        const name = result["name"];
        const color = result["color"];
        const isPrivate = result["isPrivate"];
        const signable = result["signable"];
        const portalVisitCompatibility = result["portalVisitCompatibility"];

        const typeId = param.type.id;
        const profileId = this.authService.getLoggedInUserId();
        this.endpointClientService
          .updateCategoryType(
            {
              name: name,
              color: color,
              isPrivate: isPrivate,
              signable: signable,
              portalVisitCompatibility: portalVisitCompatibility,
            },
            profileId,
            typeId
          )
          .subscribe((data) => { });
        this.endpointClientService
          .getCategories(
            profileId,
            this.authService.returnActiveAccountId(),
            this.authService.returnActiveAccountType(),
            this.authService.isOrphan
          )
          .subscribe((data) => {
            setCategories(data);
          });
      }
      this.update_category.emit(true);
    });
  }

  public addAllPersonsHotelStatic(structure) {
    let numAdultosFiltroFA = this.functionsService.findFAWithTableField(1, "hotel_reservas", "num_adultos_habitacion", structure["id_functional_parent_initial_dsb"]);
    let numAdultosFA = this.functionsService.findFAWithTableField(1, "hotel_reservas_estancias_tipo", "num_adultos", structure["id_functional_parent_initial_dsb"]);
    console.log("numAdultosFiltroFA", numAdultosFiltroFA, numAdultosFA);
    if(numAdultosFiltroFA && numAdultosFiltroFA['tmp_value'] && numAdultosFA) {
      this.functionsService.assignValueFunctionalArea(numAdultosFA, numAdultosFiltroFA['tmp_value']);
    }

    let numNinosFiltroFA = this.functionsService.findFAWithTableField(1, "hotel_reservas", "num_ninos_habitacion", structure["id_functional_parent_initial_dsb"]);
    let numNinosFA = this.functionsService.findFAWithTableField(1, "hotel_reservas_estancias_tipo", "num_ninos", structure["id_functional_parent_initial_dsb"]);
    if(numNinosFiltroFA && numNinosFiltroFA['tmp_value'] && numNinosFA) {
      this.functionsService.assignValueFunctionalArea(numNinosFA, numNinosFiltroFA['tmp_value']);
    }
    
    let numBebesFiltroFA = this.functionsService.findFAWithTableField(1, "hotel_reservas", "num_bebes_habitacion", structure["id_functional_parent_initial_dsb"]);
    let numBebesFA = this.functionsService.findFAWithTableField(1, "hotel_reservas_estancias_tipo", "num_bebes", structure["id_functional_parent_initial_dsb"]);
    if(numBebesFiltroFA && numBebesFiltroFA['tmp_value'] && numBebesFA) {
      this.functionsService.assignValueFunctionalArea(numBebesFA, numBebesFiltroFA['tmp_value']);
    }
  }

  getTextInmueble(structure, param, finished, type) {
    let idCompany = this.authService.empresaId;
    let language = null;
    let idProperty = null;
    let validReq = this.functionsService.formsArray(structure, param, false);
    let values = JSON.parse(JSON.stringify(this.functionsService.tmpArray));
    let table = null;
    let field = null;

    for (let i in values) {
      if (
        values[i]["database"] == 1 &&
        values[i]["table"] == "vivienda_comodin" &&
        values[i]["field"] == "idioma"
      ) {
        language = values[i]["value"];
      }
      if (
        values[i]["database"] == 1 &&
        values[i]["table"] == "vivienda" &&
        values[i]["field"] == "id"
      ) {
        idProperty = values[i]["value"];
      }
    }

    if (type == 1 || type == 4) {
      table = "whatsapp_cliente_comodin";
      field = "mensaje";
    } else if (type == 2 || type == 5) {
      table = "send_buffer_email";
      field = "content";
      //table = "email_comodin";
      //field = "mensaje";
    } else if (type == 3 || type == 6) {
      table = "send_buffer_email";
      field = "subject";
      //table = "email_comodin";
      //field = "asunto";
    }

    let value = -1;
    if (type == 1 || type == 2 || type == 3) {
      value = idCompany;
    } else if (type == 4 || type == 5 || type == 6) {
      value = idProperty;
    }

    this.endpointService
      .getTextInmueble(value, language, type)
      .subscribe((data) => {
        let message = "";
        if (
          data["response"].length > 0 &&
          data["response"][0]["mensaje"] !== undefined
        ) {
          message = data["response"][0]["mensaje"];
        }

        if (message != null && message != "") {
          let idFA = this.genericService.findFAWithTableField(1, table, field, structure["id_functional_parent_initial_dsb"]);
          this.functionsService.assignValueFunctionalArea(idFA, message);
          if (type == 1 || type == 2 || type == 3) {
            this.genericService.openSnackBar(
              "¡Ya lo tienes! Puedes modificarlo para este envio, pero no se guardará como configuración para casos futuros.",
              15000,
              ["green-snackbar"]
            );
          } else if (type == 4 || type == 5 || type == 6) {
            this.genericService.openSnackBar(
              "¡Ya lo tienes! Puedes modificarlo para este envio, pero no se guardará como configuración para casos futuros. Para que se guarde es necesario que le des al botón una vez hayas modificado el texto.",
              15000,
              ["green-snackbar"]
            );
          }
        } else if (type == 1 || type == 2 || type == 3) {
          this.genericService.openSnackBar(
            "Parece que no se ha definido el mensaje por defecto de la empresa.",
            6000,
            ["red-snackbar"]
          );
        } else if (type == 4 || type == 5 || type == 6) {
          this.genericService.openSnackBar(
            "Parece que no se ha definido el mensaje por defecto del inmueble.",
            6000,
            ["red-snackbar"]
          );
        }
        finished = true;
      });
  }

  buildURLFichaPropietario() {
    let idLanguage = null;
    let nombrePlantilla = null;
    //  let nombreEmpresa = this.authService.companyGenericName;
    let nombreEmpresa = null;
    let idEmpresa = 0;
    let idUsuario = this.authService.userId;
    let idVivienda = 0;
    let values = JSON.parse(JSON.stringify(this.functionsService.tmpArray));
    for (let i in values) {
      if (
        values[i]["database"] == 1 &&
        values[i]["table"] == "vivienda_comodin" &&
        values[i]["field"] == "idioma"
      ) {
        idLanguage = values[i]["value"];
      }
      if (
        values[i]["database"] == 1 &&
        values[i]["table"] == "vivienda_comodin" &&
        values[i]["field"] == "plantilla"
      ) {
        nombrePlantilla = values[i]["value"];
      }
      if (
        values[i]["database"] == 1 &&
        values[i]["table"] == "vivienda_comodin" &&
        values[i]["field"] == "propiedad"
      ) {
        idVivienda = values[i]["value"];
      }
      if (
        values[i]["database"] == 1 &&
        values[i]["table"] == "vivienda" &&
        values[i]["field"] == "gestionado_por_1"
      ) {
        idUsuario = values[i]["value"];
      }
      if (
        values[i]["database"] == 1 &&
        values[i]["table"] == "vivienda_comodin" &&
        values[i]["field"] == "id_empresa"
      ) {
        idEmpresa = values[i]["value"];
      }
      if (
        values[i]["database"] == 1 &&
        values[i]["table"] == "vivienda_comodin" &&
        values[i]["field"] == "url_empresa"
      ) {
        nombreEmpresa = values[i]["value"];
      }
    }

    return (
      Values.ROOT_URL +
      "/" +
      idLanguage +
      "/" +
      nombreEmpresa +
      "/" +
      nombrePlantilla +
      "/sth/" +
      idEmpresa +
      "-" +
      idUsuario +
      "&&" +
      idVivienda
    );
  }

  buildURLVerForm(structure, param) {
    let idLanguage = null;
    let nombrePlantilla = null;
    let nombreEmpresa = this.authService.companyGenericName;
    let intern_data = this.genericService.getInternParam(structure, param);

    for (let i in intern_data) {
      if (
        intern_data[i]["id_db"] == 3 &&
        intern_data[i]["bd_table"] == "languages" &&
        intern_data[i]["bd_field"] == "label"
      ) {
        idLanguage = intern_data[i]["value"];
      }
      if (
        intern_data[i]["id_db"] == 3 &&
        intern_data[i]["bd_table"] == "statichtml_routing_functional_area_language" &&
        intern_data[i]["bd_field"] == "internal_routing"
      ) {
        nombrePlantilla = intern_data[i]["value"];
      }
    }

    return (
      Values.ROOT_URL +
      "/" +
      idLanguage +
      "/" +
      nombreEmpresa +
      "/" +
      nombrePlantilla +
      "/sth"
    );
  }

  urlCheckOutReservaHotel(structure, param, first) {
    let validReq = this.functionsService.formsArray(structure, param);
    let values = JSON.parse(JSON.stringify(this.functionsService.tmpArray));
    if (validReq) {
      let fechaInicio = null;
      let fechaFin = null;
      let n_adultos = null;
      let n_ninos = 0;
      let n_bebes = 0;
      let idLanguage = "es";
      let idEmpresa = "0";
      let nombreEmpresa = "4DMOVIN";
      let idUser = "0";
      for (let i in values) {
        if (values[i]["database"] == 1 && values[i]["table"] == "hotel_reservas" && values[i]["field"] == "fecha_entrada") {
          fechaInicio = values[i]["value"];
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "hotel_reservas" && values[i]["field"] == "fecha_salida") {
          fechaFin = values[i]["value"];
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "hotel_reservas" && values[i]["field"] == "num_adultos_habitacion") {
          n_adultos = values[i]["value"];
          if (typeof n_adultos === 'string') {
            n_adultos = parseInt(n_adultos, 10);
          }
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "hotel_reservas" && values[i]["field"] == "num_ninos_habitacion") {
          n_ninos = values[i]["value"];
          if (typeof n_ninos === 'string') {
            n_ninos = parseInt(n_ninos, 10);
          }
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "hotel_reservas" && values[i]["field"] == "num_bebes_habitacion") {
          n_bebes = values[i]["value"];
          if (typeof n_bebes === 'string') {
            n_bebes = parseInt(n_bebes, 10);
          }
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "idiomas" && values[i]["field"] == "label") {
          idLanguage = values[i]["value"];
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "empresa" && values[i]["field"] == "id" && values[i]["value"]) {
          idEmpresa = values[i]["value"];
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "empresa" && values[i]["field"] == "nombre" && values[i]["value"]) {
          nombreEmpresa = values[i]["value"];
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "usuarios" && values[i]["field"] == "id" && values[i]["value"]) {
          idUser = values[i]["value"];
        }
      }


      this.authService.reservaInfo.push({ 'fecha_entrada': fechaInicio, 'fecha_salida': fechaFin, 'adultos': n_adultos, 'ninos': n_ninos, 'bebes': n_bebes, 'personas': (n_adultos) + (n_ninos) + n_bebes });
      this.authService.numPersonas = n_adultos + n_ninos + n_bebes;
      this.authService.numAdultos = n_adultos;
      this.authService.numNinos = n_ninos;
      this.authService.numBebes = n_bebes;
      const date1: Date = new Date(fechaInicio);
      const date2: Date = new Date(fechaFin);

      const formattedDate1 = date1.toISOString().slice(0, 10);
      const formattedDate2 = date2.toISOString().slice(0, 10);

      // Calculate the difference in milliseconds
      const differenceInMilliseconds: number = Math.abs(date2.getTime() - date1.getTime());
      const numberOfDays: number = Math.ceil(differenceInMilliseconds / (24 * 60 * 60 * 1000));

      console.log("NUMMMMMMMMMM DAYS", numberOfDays, "INFOOO", this.authService.numPersonas);
      this.authService.daysCounter = numberOfDays;

      //Casos generals generic
      this.genericService.formsChanged[this.genericService.currentInitialArea["key"]][this.genericService.paramControlVariables[this.genericService.currentInitialArea["key"]]['indexParam']] = new Map();

      let url = "../" + idLanguage + "/" + nombreEmpresa + "/" + structure['internal_routing'] + "/sth/" + idEmpresa + "-" + idUser + "&&" + formattedDate1 + "&&" + formattedDate2 + "&&" + n_adultos + "&&" + n_ninos + "&&" + n_bebes;
      return url;
    }
    return null;
  }

  openExternalUrl(structure) {
    if (structure["internal_routing"].includes("movin.cloud") || structure["internal_routing"].includes("4dmovin.com")) structure["internal_routing"] = structure["internal_routing"].replaceAll(" ", "-");
    window.open(structure["internal_routing"], "_blank");
  }

  openContratoReserva(structure, param, from = 1) {
    let idContrato = null;
    let idPropietario = null;
    let idInquilino = null;
    let route = null;
    let idLanguage = this.authService.labelLanguage;
    let idEmpresa = this.authService.getIdEmpresa();
    let nombreEmpresa = this.authService.companyGenericName;
    let intern_data = this.genericService.getInternParam(structure, param);
    console.log(intern_data, "intern_dataintern_data");
    for (let i in intern_data) {
      if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "gest_alquiler_contratos" && intern_data[i]["bd_field"] == "id_contrato" && intern_data[i]["value"] && intern_data[i]["value"] > 0) {
        idContrato = intern_data[i]["value"];
      }
      if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "empresa_contratos_tipo" && intern_data[i]["bd_field"] == "url_statichtml" && intern_data[i]["value"]) {
        route = intern_data[i]["value"];
      }
      if (from == 2 && intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "vivienda" && intern_data[i]["bd_field"] == "id_propietario_comodin" && intern_data[i]["value"] && intern_data[i]["value"] > 0) {
        idPropietario = intern_data[i]["value"];
      }
      if (from == 3 && intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "gest_alquiler_reservas_clientes" && intern_data[i]["bd_field"] == "id_cliente" && intern_data[i]["value"] && intern_data[i]["value"] > 0) {
        idInquilino = intern_data[i]["value"];
      }
    }

    //Casos generals generic
    this.genericService.formsChanged[this.genericService.currentInitialArea["key"]][this.genericService.paramControlVariables[this.genericService.currentInitialArea["key"]]['indexParam']] = new Map();

    let url = "../" + idLanguage + "/" + nombreEmpresa + "/" + route + "/sth/" + idEmpresa + "-" + this.authService.userId + "&&" + idContrato + "&&" + idPropietario + "&&" + idInquilino;
    window.open(url);
  }

  copyLinkForm(structure, param, iframe = 0) {
    let idForm = 0;
    let idLanguage = this.authService.labelLanguage;
    let idEmpresa = this.authService.getIdEmpresa();
    let nombreEmpresa = this.authService.companyGenericName;
    let intern_data = this.genericService.getInternParam(structure, param);
    let validReq = this.functionsService.formsArray(structure, param);
    if (validReq) {
      for (let i in intern_data) {
        if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "forms_mkt_empresas" && intern_data[i]["bd_field"] == "id_form" && intern_data[i]["value"] && intern_data[i]["value"] != 0) {
          idForm = intern_data[i]["value"];
        }
      }
    }
    let textToCopy = Values.ROOT_URL + "/" + idLanguage + "/" + nombreEmpresa + "/contacto-web/sth/" + idEmpresa + "&&" + idForm;
    if (iframe == 1) {
      textToCopy = '<iframe style="width: 100%;" src="' + textToCopy + '" frameBorder="0"></iframe>'
    }
    this.functionsService.clipboard.copy(textToCopy);
    this.genericService.openSnackBar("Copiado en el portapapeles", 3000, ["green-snackbar"]);
  }

  public openCashRest() {
    this.printTicketService.openCashRestaurant(this.authService.getIdCompany());
  }

  public deleteAllPictures(structure, param, id_from = 0) {
    let validReq = this.functionsService.formsArray(structure, param);
    let values = JSON.parse(JSON.stringify(this.functionsService.tmpArray));

    if (validReq) {
      let idInmueble = null;
      for (let i in values) {
        if (values[i]["database"] == 1 && values[i]["table"] == "vivienda" && values[i]["field"] == "id") {
          idInmueble = values[i]["value"];
          break;
        }
      }
      if (idInmueble && id_from == 0) {
        //Borra totes les imatges
        this.endpointClientService.deleteAllPictures(idInmueble).subscribe((data) => {
          this.genericService.openSnackBar(
            "Imágenes borradas correctamente",
            7000,
            ["green-snackbar"]
          );
          this.genericService.refreshStructure(0);
        });
      }
      else if (idInmueble && id_from == 1) {
        //Borra només les imatges despublicades
        this.endpointClientService.deleteUnpublishedPictures(idInmueble).subscribe((data) => {
          this.genericService.openSnackBar(
            "Imágenes borradas correctamente",
            7000,
            ["green-snackbar"]
          );
          this.genericService.refreshStructure(0);
        });
      }
    }
  }

  public ngForRelacionTipoCliente(structure, param, isFirstLoad) {
    if (!structure.tiposCliente) structure.tiposCliente = [];
    if(isFirstLoad) {
      // Creamos el "tiposCliente" según carga de la bd
      if (structure.tmp_value && structure.tmp_value.length > 0) {
        let ngForIndex = 1;
        for (let i in structure.tmp_value) {
          let newTipo = { value: structure.tmp_value[i], ngForId: ngForIndex };
          structure.tiposCliente.push(newTipo);
          ngForIndex++;
        }
      }
    } else {
      // Afegir nous elements
      if(structure.tmp_value && structure.tmp_value.length > structure.tiposCliente.length) {
        for (let i in structure.tmp_value) {
          if (!structure.tiposCliente.some(item => item.value === structure.tmp_value[i])) {
            let newTipo = { value: structure.tmp_value[i], ngForId: null };
            let max = this.functionsService.duplicateElementUnified(structure, param);
            for(let k in max) {
              let idFAId = this.functionsService.findElementWithId(structure['internal_routing_id_functional_area'] + "." + max[k], false, false, true);
              this.functionsService.assignValueFunctionalArea(idFAId, structure.tmp_value[i]);
              let actualNgForId = this.functionsService.getNgForId(idFAId);
              let split = actualNgForId.split(".");
              if (split[1]) actualNgForId = split[1];
              newTipo.ngForId = actualNgForId;
            }
            structure.tiposCliente.push(newTipo);
          }
        }
      } else {
        // Eliminar elements que ja no existeixen
        for (let j = 0; j < structure.tiposCliente.length; j++) {
          let element = structure.tiposCliente[j];
          let found = !structure.tmp_value || structure.tmp_value.some(item => item === element.value);
          if (!found) {
            let idFaSearch = structure["duplicate_id_functional_area"] + "." + structure.tiposCliente[j]['ngForId'];
            let idFA = this.functionsService.findElementWithId(idFaSearch, false, false, true);
            if (idFA) this.functionsService.deleteFunctionalArea(idFA, true);
            structure.tiposCliente.splice(j, 1);
            j--;
            break;
          }
        }
      }
    }
  }
}