import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-canvas-editor',
  templateUrl: './canvas-editor.component.html',
  styleUrls: ['./canvas-editor.component.scss']
})
export class CanvasEditorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
